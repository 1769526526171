import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidbar } from "../../slices/actionSlice";
import { Link } from "react-router-dom";
import { logOut } from "../../slices/authSlice";
function Navbar() {
  const { user_info } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const showMenu = () => {
    dispatch(toggleSidbar(true));
    window.Helpers.toggleCollapsed();
  };
  return (
    <nav
      className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar"
    >
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <button className="nav-item nav-link px-0 me-xl-4" onClick={showMenu}>
          <i className="bx bx-menu bx-sm" />
        </button>
      </div>
      <div
        className="navbar-nav-right d-flex align-items-center"
        id="navbar-collapse"
      >
        <ul className="navbar-nav flex-row align-items-center ms-auto">
          <li className="nav-item navbar-dropdown dropdown-user dropdown">
            <button
              className="nav-link dropdown-toggle hide-arrow"
              data-bs-toggle="dropdown"
            >
              <div className="avatar avatar-online">
                <img
                  src="../../assets/img/avatars/1.png"
                  alt="User Avatar"
                  className="w-px-40 h-auto rounded-circle"
                />
              </div>
            </button>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <div className="dropdown-item">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar avatar-online">
                        <img
                          src="../../assets/img/avatars/1.png"
                          alt="User Avatar"
                          className="w-px-40 h-auto rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <span className="fw-medium d-block text-capitalize">
                        {user_info.name || "John Doe"}
                      </span>
                      <small className="text-muted">
                        {user_info.roleName?.name || "User"}
                      </small>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="dropdown-divider" />
              </li>
              <li>
                <Link to="/profile" className="dropdown-item">
                  <i className="bx bx-user me-2" />
                  <span className="align-middle">My Profile</span>
                </Link>
              </li>
              <li>
                <Link to="/settings" className="dropdown-item">
                  <i className="bx bx-cog me-2" />
                  <span className="align-middle">Settings</span>
                </Link>
              </li>
              <li>
                <Link to="/billing" className="dropdown-item">
                  <span className="d-flex align-items-center align-middle">
                    <i className="flex-shrink-0 bx bx-credit-card me-2" />
                    <span className="flex-grow-1 align-middle ms-1">
                      Billing
                    </span>
                    <span className="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">
                      4
                    </span>
                  </span>
                </Link>
              </li>
              <li>
                <div className="dropdown-divider" />
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => dispatch(logOut())}
                >
                  <i className="bx bx-power-off me-2" />
                  <span className="align-middle">Log Out</span>
                </button>
              </li>
            </ul>
          </li>
          {/* User */}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
