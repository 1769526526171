import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css'; // Import the default Flatpickr CSS


import * as Yup from "yup";
import TextInput from "../components/TextInput";

import {

  createItemCategory,
  updateitemCategory,

  //material
  mfetchList,
  mcreateMaterialCategory,
  mupdatematerialCategory,
} from "../slices/statusSlice";


import { CirclePicker } from "react-color";
import { updateProposals, fetchLists } from "../slices/proposalSlice";

// Additional colors you want to include
const additionalColors = [
  "#FF0000", // Red
  "#00FF00", // Green
  "#0000FF", // Blue
  "#FFFF00", // Yellow
  "#FF00FF", // Magenta
  "#808080", // Gray
];

// Combine the default colors with additional colors
const allColors = [...CirclePicker.defaultProps.colors, ...additionalColors];

export default function ProposalStatusAddEditModel(props) {
  const { initialState, actionType, filter, isFrom, list2 } = props;
  // Create a ref to store the Formik instance
  const [activeIndex, setactiveIndex] = useState(null)
  const [upateColorId, setupateColorId] = useState()
  const formikRef = React.useRef(null);
  const [defautStatus, setdefautStatus] = useState([])

  const dispatch = useDispatch();
  const {
    itemCategory: { loading },
  } = useSelector((state) => state);

  const editSchema = Yup.object({
    name: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (initialState) {
      // setSelectedDate(new Date(initialState?.selectedDate || null))
      setdefautStatus(initialState?.selectedDate)
      let find = list2?.findIndex(ite => ite?._id === initialState?.status?._id);
      if (find !== -1) {
        setactiveIndex(find)
      } else {
        setdefautStatus(list2)
        setactiveIndex(null)

      }
    }
  }, [actionType, initialState]);

  // useEffect(() => {
  //   setdefautStatus(list2)
  // }, [list2])


  const callback = () => {
    let ele = document.getElementById("event_close");
    ele?.click();
    switch (isFrom) {
      case "item":
        dispatch(fetchLists(filter));
        break;


      default:
        break;
    }
  };

  const modalRef = React.useRef(null);
  const [editData, setEditdata] = useState({
    name: "",
  });

  // Define Yup schema
  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
  });

  // const [selectedDate, setSelectedDate] = useState([]);

  const handleDateChange = (dates, idx) => {
    setdefautStatus(prev => {
      const updatedSelectedDate = [...prev]; // Copy the previous state array
      if (!updatedSelectedDate[idx]) {
        updatedSelectedDate[idx] = {}; // Initialize object if it doesn't exist
      }
      updatedSelectedDate[idx] = { ...updatedSelectedDate[idx], date: dates[0] }; // Update the date for the specific index
      return updatedSelectedDate;
    });
  };

  // Function to delete a task
  const deleteTask = (index) => {
    const updatedTasks = [...defautStatus];
    updatedTasks[index] = {
      ...updatedTasks[index],
      date: ''
    }
    setdefautStatus(updatedTasks);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    let data = {
      status: upateColorId,
      selectedDate: defautStatus
    };

    console.log(data, 'data');
    dispatch(
      updateProposals({ ...data, editId: initialState._id })
    ).finally(callback);
  };

  return (
    <>
      <div
        className="modal  fade new_model"
        id="peditCategory"
        tabIndex={-1}
        aria-hidden="true"
        ref={modalRef}
      >
        <div className="modal-dialog  modal-simple modal-edit-user ">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                id="event_close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div
                // id="editUserForm"
                className="row g-3 "
                style={{ justifyContent: 'space-between' }}
              // onSubmit={submitHandler}
              >
                {defautStatus?.map((item, idx) => {
                  console.log(item, 'item?.date');
                  return (
                    <div className="row_dd">
                      <div className="" key={idx} onClick={() => {
                        setupateColorId(item?._id)
                        setdefautStatus(prev => [
                          ...prev.slice(0, idx),
                          {
                            ...prev[idx],
                            date: item?.date ? item?.date : new Date()
                          },
                          ...prev.slice(idx + 1)
                        ])
                        setactiveIndex(idx)
                      }}>
                        <button className={`btn_statu ${activeIndex === idx && 'active'}`}>
                          {item?.name}
                        </button>
                      </div>
                      <div className="" key={idx}>
                        <div className="form-group">
                          <Flatpickr
                            value={new Date(item?.date)}
                            disabled={activeIndex !== idx}
                            onChange={(e) => handleDateChange(e, idx)}
                            id="multicol-birthdate"
                            // className={`form-control`}                            
                            placeholder="MM/DD/YYYY"
                            class="form-control dob-picker"

                            options={{
                              dateFormat: 'm/d/Y', // Set the date format
                              allowInput: true, // Allow manual input
                            }}
                          />
                        </div>
                      </div>
                      {
                        activeIndex !== idx ?
                          <span
                            style={{ color: "#F89c1c" }}
                            className="btn btn-sm ms-2 del_ic "
                            onClick={() => deleteTask(idx)}
                          >
                            <i className="bx bx-trash"></i>
                          </span>
                          : <span
                            style={{ color: "#F89c1c", visibility: "hidden" }}
                            className="btn btn-sm ms-2 del_ic "

                          >
                            <i className="bx bx-trash"></i>
                          </span>
                      }
                    </div>
                  )

                })}


                <div className="col-12 " style={{ textAlign: "right" }}>
                  <button
                    type="reset"
                    className="btn btn-label-secondary me-sm-3 me-1"
                    data-bs-dismiss="modal"
                    id="event_close"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary" onClick={submitHandler}>
                    {loading === "loading" && (
                      <i class="fa fa-spinner fa-spin"></i>
                    )}
                    {"actionType" === "edit" ? "Update" : "Submit"}
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div >
    </>
  );
}
