const data1 = [
  {
    id: 1,
    name: "Product A",
    p_id: "P001",
    price: 20.99,
    time: "10:00 AM",
    icon: "icon1",
  },
];
const data2 = [
  {
    id: 2,
    name: "Product A",
    p_id: "P001",
    price: 20.99,
    time: "10:00 AM",
    icon: "icon1",
  },
];
const data3 = [
  {
    id: 3,
    name: "Product A",
    p_id: "P001",
    price: 20.99,
    time: "10:00 AM",
    icon: "icon1",
  },
];
const data4 = [
  {
    id: 4,
    name: "Product A",
    p_id: "P001",
    price: 20.99,
    time: "10:00 AM",
    icon: "icon1",
  },
];

export { data1, data2, data3, data4 };
