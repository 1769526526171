import React, { useState, useEffect } from 'react'

import {
    calculateExtraExpense,
    calculateMaterialCost,
    calculatePayment,
    calculateItemTotalPrice,
    calculateSellingPrice,
    getAgentsPercentage,
    formatter,
    calculateTotalTimeCost,
    calculateTotalTime,
    getUniqe,
    finalItemPrice,
    finalcalculateMaterialCost,
    costOfTotalTime,
    totalLabour, estimatedMarkup,
    calculateHourlyTotalCost,
    estimatedCost,
    calculateTotalMaterialCost,
    getAdjustSelling, getAgentsPercentageCommisson, getTheTotalDiscount, totalcalculateExtraExpense, getAdjustSelling1, getTheTotalItemCost
} from "../../constants/constants";
import { useParams } from 'react-router-dom';
import PDF_Create from './PDF';

export default function Preview({
    mainState,
    topDeatils,
    selectedCat,
    finalState,
    setfinalState,
    setactiveStepe,
    finalState2,
    setfinalState2,
    submit,
    list,
    formData,
    crudStatus,
    hideAll, files
}) {
    const { editId } = useParams()
    const [loading, setLoading] = useState(false);

    const handleFormSubmission = async (invoiceData) => {
        // setLoading(true);

        // const formData = new FormData();

        // const {
        //     details: { companyLogo },
        // } = invoiceData;

        // formData.append('companyLogo', companyLogo);

        // invoiceData.details.companyLogo = '';

        // formData.append('invoiceData', JSON.stringify(invoiceData));

        // try {
        //     const postResponse = await axiosInstance.post('/create', formData, {
        //         headers: { 'Content-Type': 'multipart/form-data' },
        //     });

        //     toast.success(postResponse.data?.message);

        //     const getResponse = await axiosInstance.get('/download', {
        //         responseType: 'blob',
        //     });

        //     const invoicePdfBlob = new Blob([getResponse.data], {
        //         type: 'application/pdf',
        //     });

        //     const invoicePreviewUrl = URL.createObjectURL(invoicePdfBlob);

        //     window.open(invoicePreviewUrl, '_blank');

        //     saveAs(invoicePdfBlob, `invoice_${new Date().getTime()}.pdf`);
        // } catch (e) {
        //     toast.error(e.response.data?.error.message);

        //     console.error('Error:', e);
        // } finally {
        //     setTimeout(() => setLoading(false), 2000);
        // }
    };


    const calculateTotalItemPrice = () => {
        let totalItem = 0;
        mainState.map(item => {
            totalItem += Number(item?.item_total) + Number(item?.material?.reduce((acc, id) => acc + Number(id?.material_cost), 0)) + Number(item?.extra_expense)
        })
        return totalItem || 0
    }


    const calculateTotalItemPriceSingle = (item) => {
        let totalItem = 0;
        totalItem += Number(item?.item_total) + Number(item?.material?.reduce((acc, id) => acc + Number(id?.material_cost), 0)) + Number(item?.extra_expense)

        let totalCommis = 0;
        return totalItem || 0
    }

    useEffect(() => {
        let finalTotalItemPrice = 0;

        mainState?.map(item => {
            finalTotalItemPrice += finalItemPrice(item, list);
        })


        setfinalState(prev => ({
            ...prev,
            totalLabour: totalLabour(mainState),
            totalHourlyCost: calculateHourlyTotalCost(mainState, list),
            finalTotalMaterialCost: calculateTotalMaterialCost(mainState),
            ov_rent: topDeatils?.ov_rent,
            ms_rent: topDeatils?.ms_rent,
            eq_rent: topDeatils?.eq_rent,
            adjustSelling: getAdjustSelling1(mainState),
            totalItemCost: calculateTotalItemPrice(mainState, list),
            totalPrice: getAdjustSelling1(mainState),
            finalTotalItemPrice: finalTotalItemPrice
        }))

        // 

    }, [mainState, list, topDeatils])

    useEffect(() => {
        let esTkp = estimatedMarkup(finalState, getAgentsPercentage(topDeatils?.agent, list[3]?.g_value) || 113);
        let estC = estimatedCost(finalState, finalState2?.agent);
        let discount = getTheTotalDiscount(topDeatils, finalState?.adjustSelling);
        let totalPriceWithDiscount = getAdjustSelling(mainState) - discount;

        // let totalPrice = finalState?.adjustSelling;

        setfinalState2(prev => ({
            ...prev,
            estimatedMarkup: Number(esTkp),
            estimatedCost: Number(estC),
            discountType: topDeatils?.discountType,
            discountPr: topDeatils?.ds_rent || 0,
            totalPriceWithDiscount: totalPriceWithDiscount,
            totalExtraExpense: totalcalculateExtraExpense(topDeatils),
            discount: getTheTotalDiscount(topDeatils, finalState?.adjustSelling),
            agent: getAgentsPercentageCommisson(topDeatils?.agent, totalPriceWithDiscount),
            estimatedTotal: Number(esTkp) + Number(estC),
            profit: Number(totalPriceWithDiscount) - Number(estC),
            newMarkup: getAgentsPercentage(topDeatils?.agent, list[3]?.g_value)

        }))
    }, [finalState,])

    // return <><PDF_Create /></>
    return (
        <>

            {/* <button onClick={handleFormSubmission}>
                Check
            </button> */}
            <div className="modal-edit-user2 ">
                <h4 className="text-center">{topDeatils?.name || '--'}</h4>
                <hr class="my-4 mx-n4" />

                <div className="card mb-4 total_footer" >
                    <div className="" style={{ fontSize: '1rem' }}>
                        Daily Hourly Rate : ${list[0]?.g_value || 125}
                    </div>
                    <div className=" " style={{ fontSize: '1rem' }}>
                        Aluminium Cost : ${list[1]?.g_value || 125}
                    </div>
                    <div className=" " style={{ fontSize: '1rem' }}>
                        Glass Cost : ${list[2]?.g_value || 125}
                    </div>
                    <div className=" " style={{ fontSize: '1rem' }}>
                        Mark up Percent: {list[3]?.g_value || 113}%
                    </div>
                    <div className=" " style={{ fontSize: '1rem' }}>
                        New Mark up Percent: {getAgentsPercentage(topDeatils?.agent, list[3]?.g_value) || 113}%
                    </div>
                </div>

                <div class="container-xxl flex-grow-1 container-p-y pl-0" style={{ paddingLeft: 0, paddingRight: 10 }}>
                    <div class="row">
                        <div class="col-lg-8 mb-4 order-0" >
                            <div class="card" >
                                <div class="d-flex align-items-end row">
                                    <div class="col-sm-12">
                                        <div class="card-body">
                                            <div className="ele" >
                                                Customer Name: <b>{formData?.name}</b>
                                            </div>
                                            <div className="ele" >
                                                Customer Billing Address: <b>{formData?.billing_address}</b>
                                            </div>
                                            <div className="ele" >
                                                Customer Job Site Address: <b>{selectedCat?.label}</b>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {!!hideAll &&
                            <div class="col-lg-4 col-md-6 card ">
                                <div class="col-lg-6 pt-2"><b>Proposal Image</b></div>
                                <div class="col-lg-6 ">
                                    <div className='prop_img'>
                                        <img src={files?.preview} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {mainState?.map((item, idx) => {
                    return <>
                        <div className="card font_s" key={idx} style={{ width: 'inital' }}>
                            <h4 className="text-center mt-2" style={{ textTransform: 'capitalize' }}>{item.item_code?.label} Detail
                            </h4>
                            <div class="table-responsive " style={{ paddingRight: '35px' }}>
                                <hr class="my-1 mx-n4" />
                                <table class="table table-striped mb-5 hide_righ">
                                    <thead>
                                        <tr>
                                            <th style={{ paddingRight: '0' }}>Item Image</th>
                                            <th>Quantity</th>
                                            <th>Description</th>
                                            <th>Location</th>
                                            <th>Glass</th>
                                            <th>Aluminium</th>
                                            <th>Fixed Price</th>
                                            <th>Shop Time</th>
                                            <th>Installation Time</th>
                                            <th>Total Time</th>
                                            <th>Time Cost($)</th>
                                            <th>Total Cost($)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <img src={item?.item_code?.imgUrl} className="table_img" />
                                            </td>
                                            <td>{item?.qty}</td>
                                            <td style={{ wordWrap: 'break-word', maxWidth: '150px' }}>{item?.description}</td>

                                            <td style={{ wordWrap: 'break-word', maxWidth: '150px' }}>{item?.location}</td>
                                            <td>{item?.glass_sqft}</td>
                                            <td>{item?.alumunium_pond}</td>
                                            <td>{item?.fixed_price}</td>
                                            <td>{Number(item?.shop_hr) + ":" + Number(item.shop_mm)}</td>
                                            <td>{Number(item?.install_hr) + ":" + Number(item.install_mm)}</td>
                                            <td>{calculateTotalTime(item, list)}</td>
                                            <td>{getUniqe(calculateTotalTimeCost(item, list))}</td>
                                            <td>{getUniqe(calculateItemTotalPrice(item, '', '', list))}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <>
                                    <h5 className="text-center mt-2">Detail of Material Used
                                    </h5>

                                    <table class="table table-striped" >
                                        <caption className="ms-4  ">
                                            <div className="tabl_bt">
                                                <div>
                                                    ITEM PRICE:<br /> <b>{getUniqe(finalItemPrice(item, list))}</b>
                                                </div>
                                                <div>
                                                    MATERIALS COST:<br /> <b>{getUniqe(finalcalculateMaterialCost(item.material, list))}</b>
                                                </div>
                                                <div>
                                                    EXTRA EXPENSES:<br /> <b>{getUniqe(calculateExtraExpense(topDeatils, mainState, list))}</b>
                                                </div>
                                                <div>
                                                    SELLING PRICE:<br /> <b>{getUniqe(item?.selling_price)}</b>
                                                </div>
                                                <div>
                                                    ADJUSTED SELLING PRICE:<br /> <b>{getUniqe(item?.adjustSelling)}</b>
                                                </div>
                                                <div>
                                                    COST OF TIME:<br /> <b>{getUniqe(costOfTotalTime(item, list))}</b>
                                                </div>
                                                <div>
                                                    ITEM COST:<br /> <b>{getUniqe(calculateTotalItemPriceSingle(item))}</b>
                                                </div>
                                            </div>
                                        </caption>
                                        <thead>
                                            <tr>
                                                <th>IMAGE</th>
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th>QUANTITY</th>
                                                <th>TOTAL QUANTITY</th>
                                                <th>GLASS</th>
                                                <th>ALUMINIUM</th>
                                                <th>FIXED PRICE	</th>
                                                {/* <th>TIME COST</th> */}
                                                <th>TOTAL COST</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {item?.material.map((item2, idx2) => {
                                                return <>
                                                    <tr key={idx2}>
                                                        <td>
                                                            {/* <i className="fab fa-angular fa-lg text-danger me-3" /> */}
                                                            <img src={item?.imgUrl} className="table_img" />
                                                        </td>
                                                        <td>{item2?.item_code?.label}</td>
                                                        <td style={{ wordWrap: 'break-word', maxWidth: '150px' }}>{item2?.description}</td>
                                                        <td>{item2?.qty}</td>
                                                        <td>{item2?.totalQty}</td>
                                                        <td>{item2?.glass_sqft}</td>
                                                        <td>{item2?.alumunium_pond}</td>
                                                        <td>{item2?.fixed_price}</td>
                                                        <td>{calculateMaterialCost(item2, list)}</td>

                                                    </tr>
                                                </>

                                            })}

                                        </tbody>
                                    </table>
                                </>
                            </div>
                        </div>
                        <br />
                    </>
                })}


                <div className="card mb-4 mt-4" style={{ overflow: 'hidden', padding: '10px 0' }} >
                    {/* <h4 className="fin_te">FINAL RESULT
                    </h4> */}
                    {/* <hr class="my-2 mx-n4" /> */}
                    <div className="final_result">
                        <table className='custom_table'>
                            <tr>
                                <th style={{ fontSize: '1.2rem', textAlign: 'left', paddingRight: '20px' }}>FINAL RESULT
                                </th>
                                <th></th>
                            </tr>
                            <tr>
                                <td className='make_right'>Total labor:</td>
                                <td className='tab_wid'><div className="bold_text">{finalState?.totalLabour}</div></td>
                            </tr>
                            <tr>
                                <td className='make_right'>Cost of Labor:</td>
                                <td><div className="bold_text">{getUniqe(finalState?.totalHourlyCost)}</div></td>
                            </tr>
                            <tr>
                                <td className='make_right'>Cost of Materials:</td>
                                <td><div className="bold_text">{getUniqe(finalState?.finalTotalMaterialCost)}</div></td>
                            </tr>
                            <tr>
                                <td className='make_right'>Item cost:</td>
                                <td><div className="bold_text">{getUniqe(finalState?.finalTotalItemPrice)}</div></td>
                            </tr>
                            <tr>
                                <td className='make_right'>Overhead cost:</td>
                                <td><div className="bold_text">{getUniqe(finalState?.ov_rent)} {topDeatils?.ov_des && `(${topDeatils?.ov_des})`}</div></td>
                            </tr>
                            <tr>
                                <td className='make_right'>Misc Cost:</td>
                                <td><div className="bold_text">{getUniqe(finalState?.ms_rent)} {topDeatils?.ms_des && `(${topDeatils?.ms_des})`}</div></td>
                            </tr>
                            <tr>
                                <td className='make_right'>Equipment rental:</td>
                                <td><div className="bold_text">{getUniqe(finalState?.eq_rent)}  {topDeatils?.eq_des && `(${topDeatils?.eq_des})`}</div></td>
                            </tr>


                            {finalState2?.agent?.length > 0 &&
                                finalState2?.agent?.map((item, idx) => (
                                    <>
                                        <tr>
                                            <td className='make_right'>{item?.name}: </td>
                                            <td> <div className="bold_text"> {getUniqe(item?.earn)}  {item?.type == 'percent' && `(${item?.commission}%)`}</div></td>
                                        </tr>
                                    </>
                                ))}
                            <tr>
                                <td className='make_right'>Total extra Expenses:</td>
                                <td><div className="bold_text">{getUniqe(finalState2?.totalExtraExpense)}</div></td>
                            </tr>
                            <tr class="total-row">
                                <td className='make_right'>Estimated cost:</td>
                                <td><div className="bold_text">{getUniqe(finalState2?.estimatedCost)}</div></td>
                            </tr>
                            <tr>
                                <td className='make_right'>Estimated Markup:</td>
                                <td><div className="bold_text">{getUniqe(finalState2?.estimatedMarkup)} ({finalState2?.newMarkup}%)</div></td>
                            </tr>
                            <tr class="total-row">
                                <td className='make_right'>Estimated Total:</td>
                                <td><div className="bold_text">{getUniqe(finalState2?.estimatedTotal)}</div></td>
                            </tr>
                            <tr>
                                <td className='make_right'>Adjusted Selling Price:</td>
                                <td><div className="bold_text">{getUniqe(finalState.adjustSelling)}</div></td>
                            </tr>
                            <tr class="total-row">
                                <td className='make_right'>Total Price:</td>
                                <td><div className="bold_text">{getUniqe(finalState?.adjustSelling)}</div>
                                </td>
                            </tr>
                            <tr>
                                <td className='make_right'>Discount:</td>
                                <td><div className="bold_text">{getUniqe(finalState2?.discount)} {finalState2?.discountType !== 'amount' && `(${finalState2?.discountPr}%)`}   {topDeatils?.ds_des && `(${topDeatils?.ds_des})`}</div></td>
                            </tr>
                            <tr class="total-row">
                                <td className='make_right'>Total Price with discount:</td>
                                <td><div className="bold_text">{getUniqe(finalState2?.totalPriceWithDiscount)}</div></td>
                            </tr>
                            <tr class="highlight">
                                <td className='make_right'>Profit:</td>
                                <td><div className="bold_text">{getUniqe(finalState2?.profit)}</div></td>
                            </tr>
                        </table>
                    </div>
                </div>
                {!hideAll
                    &&
                    <div className="col-12 mt-2" style={{ textAlign: "right" }}>
                        <button
                            type="reset"
                            className="btn btn-label-secondary me-3"
                            onClick={() => setactiveStepe('step3')}
                        >
                            Cancel
                        </button>

                        <button type="submit" className="btn btn-primary " onClick={submit}>
                            {crudStatus == "loading" && (
                                <i class="fa fa-spinner fa-spin"></i>
                            )}
                            {editId ? "Update" : 'Save'}
                        </button>

                    </div>
                }
            </div >
        </>
    )
}
