import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLists } from "../../slices/proposalSlice";
import moment from "moment";
import ProposalStatusAddEditModel from "../../Models/ProposalStatusAddEditModel";
import Skeleton from "../Role/skeleton";
import ChangePasswordModel from "../../Models/ChangePasswordModel";
import { getUniqe } from "../../constants/constants";
import { Link } from "react-router-dom";
import { fetchList } from "../../slices/statusSlice";

function UserList() {
  const dispatch = useDispatch();
  const { list, fetchStatus } = useSelector((store) => store.proposal);
  const store = useSelector(state => state.status);
  const [actionType, setactionType] = useState();
  const [initialState, setinitialState] = useState({});
  const [filter, setfilter] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    dispatch(fetchLists(filter));
  }, []);

  const getActionType = (type, item) => {
    switch (type) {
      case "add":
        setactionType("add");
        setinitialState({});
        break;
      case "edit":
        setactionType("edit");
        setinitialState(item);
        break;

      default:
        setactionType("");
        setinitialState({});
        break;
    }
  };

  const fetchRole = useCallback(() => {
    dispatch(fetchList({ page: 1, limit: 10 }));
  }, [actionType]);

  useEffect(() => {
    fetchRole();
  }, [fetchRole]);

  if (fetchStatus == "loading") {
    return <Skeleton />;
  }
  return (
    <>
      <ProposalStatusAddEditModel
        initialState={initialState}
        actionType={actionType}
        setinitialState={setinitialState}
        filter={filter}
        title={"Item"}
        list2={store?.list || []}
        isFrom="item"
        setactionType={setactionType}
      />

      <ChangePasswordModel
        isFrom="item"
        whatDelete="item"
        filter={filter}
        initialState={initialState}
      />
      <div className="content-wrapper">
        {/* Content */}
        <div className="container-xxl flex-grow-1 container-p-y">
          {/* <!-- Basic Bootstrap Table --> */}
          <div className="card">
            <h5 className="card-header d-flex justify-content-between">
              Proposal List
              {/* <button
                data-bs-toggle="modal"
                data-bs-target="#editUser"
                className="dt-button create-new btn btn-primary"
                tabindex="0"
                type="button"
                onClick={() => getActionType("add")}
              >
                <span>
                  <i class="bx bx-plus me-sm-1"></i>{" "}
                  <span class="d-none d-sm-inline-block">Add New</span>
                </span>
              </button> */}
            </h5>

            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Image</th>
                    <th>Job Name</th>
                    <th>Proposal id</th>
                    <th>Client Name</th>
                    <th>Created At</th>
                    <th>Estimated cost</th>
                    <th>Total price</th>
                    <th>profit </th>
                    <th>status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody className="table-border-bottom-0">
                  {list?.map((item, idx) => {
                    const { topDeatils, mainState, formData, finalState2, finalState, files } = item
                    // console.log(formData?.name, 'formData');
                    return (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td className="text-capitalize"><img className="table_img" src={files?.preview} /></td>
                        <td className="text-capitalize">{topDeatils?.name || '--'}</td>
                        <td>#{formData?.proposalId}
                        </td>
                        <td>
                          <span className="fw-medium">{formData?.name}</span>
                        </td>
                        <td>{moment(item.createdAt).format("MM/DD/YYYY")}</td>
                        <td>{getUniqe(finalState2?.estimatedCost)}</td>
                        <td>{getUniqe(finalState?.totalPrice)}</td>
                        <td>{getUniqe(finalState2?.profit)}</td>
                        <td>
                          <span
                            className="badge"

                            style={{ background: item?.status?.bgColor ? item?.status?.bgColor : 'grey' }}
                          >
                            {item?.status?.name ? item?.status?.name : "N/A"}
                          </span>
                          <i
                            data-bs-toggle="modal"
                            data-bs-target="#peditCategory"
                            onClick={() => {
                              getActionType("edit", item)
                              setactionType(item);
                            }
                            }
                            className="bx bx-dots-vertical-rounded" style={{ cursor: 'pointer' }} />
                        </td>
                        <td>
                          <div className="dropdown">
                            <button
                              type="button"
                              className="btn p-0 dropdown-toggle hide-arrow"
                              data-bs-toggle="dropdown"
                            >
                              <i className="bx bx-dots-vertical-rounded" />
                            </button>
                            <div className="dropdown-menu">
                              <Link
                                to={`/proposal-details/${item?._id}`}
                                className="dropdown-item"
                                type="button"
                              >
                                <i className="bx bx-show me-1" /> View
                              </Link>
                              <Link
                                to={`/create-proposal/${item?._id}`}
                                className="dropdown-item"
                                href="javascript:void(0);"
                                type="button"
                              >
                                <i className="bx bx-edit-alt me-1" /> Edit
                              </Link>
                              <a
                                // onClick={() => getActionType("edit", item)}
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                <i className="bx bx-trash me-1" /> Delete
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* <!--/ Basic Bootstrap Table --> */}
        </div>
        {/* / Content */}
        <div className="content-backdrop fade" />
      </div>
    </>
  );
}

export default UserList;
