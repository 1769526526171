import React, { useRef } from 'react';
import html2pdf from 'html2pdf.js';

function PDF() {
    const pdfRef = useRef();

    const loadImage = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = resolve;
            img.onerror = reject;
        });
    };

    const printHandler = async () => {
        const imageUrl = '../../assets/img/favicon/logo.png';
        await loadImage(imageUrl); // Wait for the image to load
        if (pdfRef.current) {

            html2pdf().from(pdfRef.current).set({
                html2canvas: {
                    useCORS: true, // Allow loading of cross-origin images
                    scrollX: 0,
                    scrollY: -window.scrollY,
                }
            }).save();
        }
    };

    return (
        <div className="App">
            <div ref={pdfRef} className='pdf_ref'>
                <div className='top_bar'>
                    <img src='../../assets/img/favicon/logo.png' width={200} />
                    <h2>Proposal: #0000002</h2>
                </div>
                <></>
            </div>
            <button onClick={printHandler}>Print</button>
        </div>
    );
}

export default PDF;
