import React, { useState } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { data1, data2, data3, data4 } from "./randomData";
export default function Shop() {
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="container">
        <DragDrop />
      </div>
    </DndProvider>
  );
}

const data = [
  {
    id: 1,
    name: "Product A",
    p_id: "P001",
    price: 20.99,
    time: "10:00 AM",
    icon: "icon1",
  },
  {
    id: 2,
    name: "Product B",
    p_id: "P002",
    price: 15.49,
    time: "11:30 AM",
    icon: "icon2",
  },
];

function SingleData({ product, columnIndex }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "Card",
    item: { id: product ? product.id : null, columnIndex },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      className="product-card mr-6"
      ref={drag}
      style={{ border: isDragging ? "2px solid red" : "" }}
    >
      {product && ( // Check if product is defined before rendering its properties
        <>
          <h2>{product.name}</h2>
          <p>Product ID: {product.p_id}</p>
          <p>Price: ${product.price}</p>
          <p>Time: {product.time}</p>
          <img src={`/icons/${product.icon}.svg`} alt="Product Icon" />
        </>
      )}
    </div>
  );
}

function DragDrop() {
  const [cards, setCards] = useState([
    data1,
    data2,
    data3,
    data4,
    [],
    [],
    [],
    [],
    [],
  ]);
  function dropData(itemId, dragBoxId, dropBoxId) {
    console.log("🚀 ~ dropData ~ itemId, dragBoxId, dropBoxId:", itemId, dragBoxId, dropBoxId)
    const singleItem = cards[dragBoxId].find((item) => item.id === itemId);
    const newArray = cards.map((item, index) => {
      if (index === dragBoxId) {
        return item.filter((_, i) => i !== itemId);
      }
      if (index === dropBoxId) {
        return [...item, singleItem];
      }
      return item;
    });
    setCards(newArray);
  }
  return (
    <div className="col1 d-flex gap-4">
      {cards.map((_, columnIndex) => (
        <div key={columnIndex} style={{ border: "2px solid black" }}>
          <SingleCardContainer
            columnIndex={columnIndex}
            cards={cards[columnIndex]}
            dropData={dropData}
          />
        </div>
      ))}
    </div>
  );
}

function SingleCardContainer({ columnIndex, cards, dropData }) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "Card",
    drop: (item) => {
      const dragColumnIndex = item.columnIndex;
      const dropColumnIndex = parseInt(columnIndex); // Use the columnIndex passed to the component
      dragCard(item.id, dragColumnIndex, dropColumnIndex);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  function dragCard(id, dragColumnIndex, dropColumnIndex) {
    dropData(id, dragColumnIndex, dropColumnIndex);
  }

  return (
    <div ref={drop} id={columnIndex}>
      {cards.map((item, rowIndex) => (
        <SingleData key={rowIndex} product={item} columnIndex={columnIndex} />
      ))}
    </div>
  );
}
